<template>
  <div>
    <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
        <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
        <iq-card>
            <template v-slot:headerTitle>
                <div class="text-center">
                    <h5 class="text-dark"> {{ $t('research_seed.first_inspection') }}</h5>
                </div>
            </template>
            <template v-slot:body>
                <b-row style="">
                    <b-col md="6" lg="6">
                        <ValidationProvider name="Start Date" vid="first_start_date" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="first_start_date"
                            slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                            {{$t('research_seed.start_date')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                                class="date-picker"
                                id="first_start_date"
                                v-model="info.first_start_date"
                                :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col md="6" lg="6">
                        <ValidationProvider name="End Date" vid="first_end_date" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="first_end_date"
                            slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                            {{$t('research_seed.end_date')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                                class="date-picker"
                                id="first_end_date"
                                v-model="info.first_end_date"
                                :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                        </ValidationProvider>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <iq-card>
            <template v-slot:headerTitle>
                <div class="text-center">
                    <h5 class="text-dark"> {{ $t('research_seed.second_inspection') }}</h5>
                </div>
            </template>
            <template v-slot:body>
                <b-row style="">
                    <b-col md="6" lg="6">
                        <ValidationProvider name="Second Start Date" vid="second_start_date" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="second_start_date"
                            slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                            {{$t('research_seed.start_date')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                                class="date-picker"
                                id="second_start_date"
                                v-model="info.second_start_date"
                                :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col md="6" lg="6">
                        <ValidationProvider name="Second End Date" vid="second_end_date" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="second_end_date"
                            slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                            {{$t('research_seed.end_date')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                                class="date-picker"
                                id="second_end_date"
                                v-model="info.second_end_date"
                                :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                        </ValidationProvider>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <iq-card>
            <template v-slot:headerTitle>
                <div class="text-center">
                    <h5 class="text-dark"> {{ $t('research_seed.third_inspection') }}</h5>
                </div>
            </template>
            <template v-slot:body>
                <b-row style="">
                    <b-col md="6" lg="6">
                        <ValidationProvider name="Third Start Date" vid="third_start_date" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="third_start_date"
                            slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                            {{$t('research_seed.start_date')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                                class="date-picker"
                                id="third_start_date"
                                v-model="info.third_start_date"
                                :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col md="6" lg="6">
                        <ValidationProvider name="Third End Date" vid="third_end_date" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="third_end_date"
                            slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                            {{$t('research_seed.end_date')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                                class="date-picker"
                                id="third_end_date"
                                v-model="info.third_end_date"
                                :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col md="6" lg="6" sm="12">
                        <ValidationProvider name="Remarks(En)"  vid="remarks" >
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="remarks"
                                >
                                    <template v-slot:label>
                                        {{$t('research_seed.remarks')}}
                                    </template>
                                    <b-form-textarea
                                        id="remarks"
                                        v-model="info.remarks"
                                        class="mb-3"
                                        name="remarks"
                                    >
                                    </b-form-textarea>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col md="6" lg="6" sm="12">
                        <ValidationProvider name="Remarks(Bn)"  vid="remarks_bn" >
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="remarks_bn"
                                >
                                    <template v-slot:label>
                                        {{$t('research_seed.remarks_bn')}}
                                    </template>
                                    <b-form-textarea
                                        id="remarks_bn"
                                        v-model="info.remarks_bn"
                                        class="mb-3"
                                        name="remarks_bn"
                                    >
                                    </b-form-textarea>
                            </b-form-group>
                        </ValidationProvider>
                        <div class="col text-right">
                            <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                            &nbsp;
                            <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                        </div>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        </b-form>
    </ValidationObserver>
  </div>
</template>

<script>
import { core } from '../../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { agriResearchServiceBaseUrl } from '../../../../../../config/api_config'
import { inspectionVerifiedAdd, inspectionVerifiedGet } from '../../../api/routes'
import researchTestingModal from '@/mixins/research-testing-modal'
import flatpickr from 'flatpickr'

export default {
    props: ['id'],
    data () {
        return {
            saveBtnName: this.$t('globalTrans.save'),
            info: {
                inspection_id: [],
                first_start_date: '',
                first_end_date: '',
                second_start_date: '',
                second_end_date: '',
                third_start_date: '',
                third_end_date: '',
                remarks: '',
                remarks_bn: ''
            }
        }
    },
    components: {
        ValidationObserver,
        ValidationProvider
    },
    mixins: [researchTestingModal],
    async created () {
        const tmp = await this.getseedData()
        this.info = { ...this.info, ...tmp.data }
    },
    mounted () {
        flatpickr('.date-picker', {})
        core.index()
    },
    methods: {
        async getseedData () {
            const tmpData = await RestApi.getData(agriResearchServiceBaseUrl, `${inspectionVerifiedGet}/${this.id[0]}`)
            if (tmpData.success) {
                this.saveBtnName = this.$t('globalTrans.update')
            }
            return JSON.parse(JSON.stringify(tmpData))
        },
        async saveData () {
            this.info.inspection_id = this.id
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            let result = null
            const loadingState = { loading: false, listReload: false }
            result = await RestApi.postData(agriResearchServiceBaseUrl, inspectionVerifiedAdd, this.info)
            loadingState.listReload = true
            this.$store.dispatch('mutateCommonProperties', loadingState)

            if (result.success) {
                this.$toast.success({
                title: 'Success',
                message: this.id ? 'Data updated successfully' : 'Data save successfully',
                color: '#D6E09B'
                })

                this.$bvModal.hide('modal-4')
            } else {
                this.$refs.form.setErrors(result.errors)
                this.$toast.error({
                title: 'Error',
                message: 'Operation failed! Please, try again.'
                })
            }
        }
    }
}
</script>

<style>

</style>
