<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('research_seed.inspection_schedule')  + ' ' + $t('globalTrans.search') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
            <!-- <b-col lg="5" md="5" sm="12" xs="12">
                <b-form-group
                class="row"
                label-cols-sm="4"
                :label="$t('research_seed.service')"
                label-for="service_info_id"
                >
                <b-form-select
                    plain
                    id="service_info_id"
                    :options="serviceList"
                    v-model="search.service_info_id"
                    placeholder=""
                >
                    <template v-slot:first>
                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                    </b-form-select>
                </b-form-group>
            </b-col>
            <b-col lg="5" md="5" sm="12" xs="12">
                <b-form-group
                class="row"
                label-cols-sm="4"
                :label="$t('globalTrans.organization')"
                label-for="org_id"
                >
                <b-form-select
                    plain
                    id="org_id"
                    :options="orgList"
                    v-model="search.org_id"
                    placeholder=""
                >
                    <template v-slot:first>
                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                    </b-form-select>
                </b-form-group>
            </b-col> -->
            <b-col lg="5" md="5" sm="12" xs="12">
                <b-form-group
                class="row"
                label-cols-sm="4"
                :label="$t('globalTrans.search')"
                label-for="seed_variety_name"
                >
                    <b-form-input
                        id="seed_variety_name"
                        v-model="search.name"
                        placeholder=""
                    >
                    </b-form-input>
                </b-form-group>
            </b-col>
          <b-col lg="2" md="2" sm="12" xs="12">
            <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
          </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('research_seed.inspection_schedule') + ' ' + $t('globalTrans.list') }}</h4>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table bordered hover :items="datas" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(applicant_name)="data">
                      <span class="capitalize">{{ data.item.applicant_name }}</span>
                    </template>
                    <template v-slot:cell(applicant_name_bn)="data">
                      {{ data.item.applicant_name_bn }}
                    </template>
                    <template v-slot:cell(applicant_org_name)="data">
                      <span class="capitalize">{{ data.item.applicant_org_name }}</span>
                    </template>
                    <template v-slot:cell(applicant_org_name_bn)="data">
                      <span class="capitalize">{{ data.item.applicant_org_name_bn }}</span>
                    </template>
                    <template v-slot:cell(seed_name)="data">
                      <span class="capitalize">{{ getSeedName(data.item.seed_name_id) }}</span>
                    </template>
                    <template v-slot:cell(seed_variety)="data">
                      <span class="capitalize">{{ getSeedVariety(data.item.seed_variety_id) }}</span>
                    </template>
                    <template v-slot:cell(seed_class)="data">
                      <span class="capitalize">{{ getSeedClass(data.item.seed_class_id) }}</span>
                    </template>
                    <template v-slot:cell(application_status)="data">
                      {{ data.item.application_status ? $t('research_seed.pending') : $t('research_seed.approved') }}
                    </template>
                    <template v-slot:cell(action)="data">
                      <b-button title="Edit" v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                      <b-button title="View" v-b-modal.modal-5 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="view(data.item)"><i class="ri-eye-line m-0 "></i></b-button>
                    </template>
                  </b-table>
                  <b-pagination
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <FormV :id="idToEdit" :key="editItemId"/>
    </b-modal>
    <b-modal id="modal-5" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Details :application="application" />
    </b-modal>
  </b-container>
</template>

<script>
import FormV from './Form.vue'
import Details from '../../../../../external-user-service/agri-research/pages/field-application-list/Application.vue'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { inspectionVerifiedList } from '../../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import researchTestingHelper from '@/mixins/agri-research-helpers'
import { mapGetters } from 'vuex'
import { core } from '@/config/pluginInit'

export default {
  mixins: [ModalBaseMasterList, researchTestingHelper],
  components: {
    FormV,
    Details
  },
  data () {
    return {
        idToEdit: [],
        datas: [],
        search: {
            name: '',
            service_info_id: '',
            org_id: ''
        },
        application: {}
    }
  },
  computed: {
    ...mapGetters({
      authUser: 'Auth/authUser'
    }),
    formTitle () {
       return (this.editItemId === 0) ? this.$t('research_seed.inspection_schedule_confirm') : this.$t('research_seed.inspection_schedule_confirm_modify')
    },
    columns () {
        const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('research_seed.application_id'), class: 'text-left' },
          { label: this.$t('research_seed.application_date'), class: 'text-left' },
          { label: this.$t('research_seed.applicant_name'), class: 'text-left' },
          { label: this.$t('globalTrans.organization'), class: 'text-left' },
          { label: this.$t('research_seed.seed_name'), class: 'text-left' },
          { label: this.$t('research_seed.seed_variety_name'), class: 'text-left' },
          { label: this.$t('research_seed.seed_class_name'), class: 'text-left' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'applicationId' },
          { key: 'created_at' },
          { key: 'applicant_name_bn' },
          { key: 'applicant_org_name_bn' },
          { key: 'seed_name' },
          { key: 'seed_variety' },
          { key: 'seed_class' },
          { key: 'application_status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'applicationId' },
          { key: 'created_at' },
          { key: 'applicant_name' },
          { key: 'applicant_org_name' },
          { key: 'seed_name' },
          { key: 'seed_variety' },
          { key: 'seed_class' },
          { key: 'application_status' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    }
  },
  mounted () {
    core.index()
    if (this.authUser.role_id === 0) {
      this.search = Object.assign({}, this.search)
    }
    this.loadData()
  },
  methods: {
    async searchData () {
      this.loadData()
    },
    loadData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(agriResearchServiceBaseUrl, inspectionVerifiedList, params).then(response => {
          if (response.success) {
            this.$store.dispatch('setList', response.data.data)
            this.paginationData(response.data)
            const allData = this.$store.state.list
            this.datas = JSON.parse(JSON.stringify(allData))
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    view (item) {
        this.application = item
    },
    edit (item) {
        this.idToEdit.unshift(item.inspection_id)
    },
    dataList (data) {
      const listData = data
      return listData
    }
  }
}
</script>
